import * as types from "../constants/ActionTypes";
import { ProfileModel } from "../../../api/models/ProfileModel";
import { UserRoleType } from "../../../api/models/UserRoleModel";

//Actions
export function userChange(response: any) {
	return {
		type: types.CHANGE_USER,
		payload: response,
	};
}

export function pictureChange(response: any) {
	return {
		type: types.CHANGE_PICTURE,
		payload: response,
	};
}

export function impersonateUserChange(response: any) {
	return {
		type: types.CHANGE_IMPERSONATE_USER,
		payload: response,
	};
}

export function impersonatePictureChange(response: any) {
	return {
		type: types.CHANGE_IMPERSONATE_PICTURE,
		payload: response,
	};
}

export function rolesChange(response: any) {
	return {
		type: types.CHANGE_ROLES,
		payload: response,
	};
}

export function userRoleChange(response: any) {
	return {
		type: types.CHANGE_USER_ROLE,
		payload: response,
	};
}

export function impersonationChange(response: string) {
	return {
		type: types.CHANGE_IMPERSONATE,
		payload: response,
	};
}

export function adminViewChange(response: boolean) {
	return {
		type: types.CHANGE_ADMINVIEW,
		payload: response,
	};
}


export function bannerMessagesChange(response: any) {
	return {
		type: types.CHANGE_BANNER_MESSAGES,
		payload: response,
	};
}


//Dispatches
export function changeUser(user: ProfileModel) {
	return (dispatch: any) => {
		dispatch(userChange(user));
	};
}

export function changePicture(picture: string) {
	return (dispatch: any) => {
		dispatch(pictureChange(picture));
	};
}

export function changeImpersonateUser(user: ProfileModel) {
	return (dispatch: any) => {
		dispatch(impersonateUserChange(user));
	};
}

export function changeImpersonatePicture(picture: string) {
	return (dispatch: any) => {
		dispatch(impersonatePictureChange(picture));
	};
}

export function changeRoles(roles: string[]) {
	return (dispatch: any) => {
		dispatch(rolesChange(roles));
	};
}

export function changeUserRole(roles: any) {
	let userRole: UserRoleType;
	if (roles.VOIP_SUPERUSER) {
		userRole = "SUPER_USER";
	} else if (roles.VOIP_ADMIN) {
		userRole = "VOIP_ADMIN";
	} else if (roles.HELPDESK) {
		userRole = "HELPDESK";
	} else {
		userRole = "UNPRIV_USER";
	}

	return (dispatch: any) => {
		dispatch(userRoleChange(userRole));
	};
}

export function changeImpersonate(kerb: string) {
	return (dispatch: any) => {
		dispatch(impersonationChange(kerb));
	};
}

export function changeAdminView(view: boolean) {
	return (dispatch: any) => {
		dispatch(adminViewChange(view));
	};
}

export function changeBannerMessages(view: boolean) {
	return (dispatch: any) => {
		dispatch(bannerMessagesChange(view));
	};
}
