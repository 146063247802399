import { appInitialized } from '@mit/aws-react';
import { useState, useEffect } from 'react';
import AccountController from "../api/AccountController";
import DeviceController from "../api/DeviceController";
import PairController from "../api/PairController";
import AppController from "../api/AppController";
import ProfileController from "../api/ProfileController";
import * as authStore from "../common/redux/actions/app_auth";
import * as appStore from "../common/redux/actions/app";
import * as lookupsStore from "../common/redux/actions/lookups";
import { store } from "../common/redux/store/store";


interface UseAppInitializationReturn {
  initializeApp: () => void;
}

const appController = new AppController();
const profileController = new ProfileController();
const accountController = new AccountController();
const deviceController = new DeviceController();
const pairController = new PairController();

export const useAppInitializer = (): UseAppInitializationReturn => {
  const [initializeApi, setInitializeApi] = useState(false);

  useEffect(() => {
    appInitialized({
      isLoading: true,
      hasAccess: false,
      hasError: false,
    });
  }, []);

  const initializeApp = async (): Promise<void> => {

      await profileController.getProfile().then((data: any) => {
        if (data) store.dispatch(appStore.changeUser(data) as any);
      }),

      await profileController.getPicture().then((data: any) => {
        if (data) store.dispatch(appStore.changePicture(data) as any); 
      }),

      await appController.getAdminRoles().then((data: any) => {
        if (data) {
          store.dispatch(appStore.changeUserRole(data) as any);
          store.dispatch(appStore.changeRoles(data) as any);
        }
      }),

      await accountController.getAccountTypes().then((data: any) => {
        if (data) store.dispatch(lookupsStore.changeAccountTypes(data) as any);
      }),

      await pairController.getSwitchSites().then((data: any) => {
        if (data) store.dispatch(lookupsStore.changeSwitchSites(data) as any);
      }),

      await pairController.getFramePartitions().then((data: any) => {
        if (data) store.dispatch(lookupsStore.changeFramePartitions(data) as any);
      }),

      
      store.dispatch(authStore.setIsLoading(false));
      store.dispatch(authStore.setIsReady(true));

      await getLookupData()
      appInitialized({
        isLoading: false,
        hasAccess: true,
        hasError: false,
      });
      await appController.getBannerMessages().then((data: any) => {
        if (data) store.dispatch(appStore.changeBannerMessages(data) as any);
      }),

      setTimeout(() => {
        setInitializeApi(true);
      }, 1000);
      
  }
  return { initializeApp }
}

const getLookupData = async () => {

  const voicemailTypes = await accountController.getVoicemailTypes();
  const deviceModels = await deviceController.getDeviceModels();
  const firmwareTypes = await deviceController.getFirmwareTypes();

  // console.log("voicemailTypes", voicemailTypes);

  store.dispatch(lookupsStore.changeVoicemailTypes(voicemailTypes) as any);
  store.dispatch(lookupsStore.changeDeviceModels(deviceModels) as any);
  store.dispatch(lookupsStore.changeFirmwareTypes(firmwareTypes) as any);

  if (store.getState().app.userRole !== "HELPDESK" && store.getState().app.userRole !== "UNPRIV_USER") {
    const pairStatuses = await pairController.getPairStatuses();
    store.dispatch(lookupsStore.pairStatusesChange(pairStatuses));
  }
};
