import React, { Fragment, FC, useEffect } from "react";
import History from "./common/History";
import { Provider } from "react-redux";
import { Router, BrowserRouter, Route, Switch } from "react-router-dom";
import { store } from "./common/redux/store/store";
import ReactDOM from 'react-dom';
import { useAppInitializer } from './services/initializer'
import { Initialization } from '@mit/aws-react';
import appConfig from './app-config-global';
import  Logout  from './common/Logout';

// export const globalStore = store;

const AppLazyLoad = React.lazy(async () => await import('./app/App').then(module => ({
	default: module.default
})))

const WrappedApp: FC = () => {
	
	let isLoading = store.getState().appAuth.isLoading
	const { initializeApp } = useAppInitializer()

	return (
		<Fragment>
			<React.StrictMode>
			<Switch>
          <Route exact path='/logout' component={Logout} />
          <Route path='/'>
				<Initialization
					App={AppLazyLoad}
					appInitializer={initializeApp}
					appConfigData={appConfig}
					loaderProps={{
						contactEmail: 'voip-help@mit.edu',
						name: 'MITvoip',
						isLoading,
						type: 'Default',
						backgroundColor: '#20447D',
					}}
				/>
				</Route>
			</Switch>
			</React.StrictMode>
		</Fragment>
	)
};

ReactDOM.render(
	<Provider store={store}>
		<Router history={History}>
			<WrappedApp />
		</Router>
	</Provider>,
	document.getElementById('root')
);
