import { ToastType } from '@mit/hui/build/components/Toast';
import * as types from '../constants/ActionTypes';

export interface NotificationsModel {
  notifications: NotificationModel[];
}

export interface NotificationModel {
  show: boolean;
  id: string;
  message: string;
  title: string;
  subtext: string;
  footer?: any;
  type?: ToastType;
}

const initialState: NotificationsModel = {
  notifications: [],
};

export default function notification(state = initialState, action: any) {
  switch (action.type) {
    case types.ADD_NOTIFICATION: {
      let update = [];

      for (var i = 0; i < state.notifications.length; i++) {
        update.push(state.notifications[i]);
      }

      update.push(action.payload);

      return { ...state, notifications: update };
    }
    case types.CLOSE_NOTIFICATION: {
      let stateItems = [];

      for (var k = 0; k < state.notifications.length; k++) {
        stateItems.push(state.notifications[k]);
      }

      let Itemindex = stateItems.findIndex((itm: NotificationModel) => {
        return itm.id === action.payload;
      });

      stateItems.splice(Itemindex, 1);

      return { ...state, notifications: stateItems };
    }
    default:
      return state;
  }
}
