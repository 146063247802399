import * as types from "../constants/ActionTypes";

const initialState = {
	user: {
		kerberos: "",
		first_name: "",
		last_name: "",
		display_name: "",
		email: "",
		department: "",
	},
	impersonateUser: {
		kerberos: "",
		first_name: "",
		last_name: "",
		display_name: " please wait... ",
		email: "",
		department: "",
	},
	picture: "",
	impersonatePicture: "",
	roles: {
		ACCOUNT_DEVICE_OWNER: false,
		ACCOUNT_ADMIN: false,
		HELPDESK: false,
		VOIP_ADMIN: false,
		VOIP_SUPERUSER: false,
		ONLINE: false,
	},
	userRole: "",
	impersonate: "",
	adminView: document.location.pathname.includes("admin") ? true : false,
	bannerMessages: [
		{
			id: 0,
			style: "",
			title: "",
			message: "",
			cancel: false
		}
	],
};

export default function app(state = initialState, action: any) {
	switch (action.type) {
		case types.CHANGE_USER: {
			return { ...state, user: action.payload };
		}
		case types.CHANGE_IMPERSONATE_USER: {
			return { ...state, impersonateUser: action.payload };
		}
		case types.CHANGE_PICTURE: {
			return { ...state, picture: action.payload };
		}
		case types.CHANGE_IMPERSONATE_PICTURE: {
			return { ...state, impersonatePicture: action.payload };
		}
		case types.CHANGE_ROLES: {
			return { ...state, roles: action.payload };
		}
		case types.CHANGE_USER_ROLE: {
			return { ...state, userRole: action.payload };
		}
		case types.CHANGE_IMPERSONATE: {
			return { ...state, impersonate: action.payload };
		}
		case types.CHANGE_ADMINVIEW: {
			return { ...state, adminView: action.payload };
		}
		default:
			return state;
	}
}
