import * as types from '../constants/ActionTypes';
import { NotificationModel } from '../reducers/notification';

export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

//Dispatch

export function dispatch_addNotification(response: any) {
  return {
    type: types.ADD_NOTIFICATION,
    payload: response,
  };
}

export function dispatch_closeNotification(response: any) {
  return {
    type: types.CLOSE_NOTIFICATION,
    payload: response,
  };
}

//Actions

export function addNotification(
  jobId: string,
  title: string,
  subtitle: string,
  message: string,
  footer: any,
  type?: any
) {
  return (dispatch: any) => {
    const notification: NotificationModel = {
      show: true,
      title: title,
      message: message,
      subtext: subtitle,
      id: jobId,
      footer: footer,
      type: type,
    };

    dispatch(dispatch_addNotification(notification));
  };
}

export function closeNotification(id: string) {
  return (dispatch: any) => {
    dispatch(dispatch_closeNotification(id));
  };
}
