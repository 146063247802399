import { AuthProvider } from '@mit/aws-react'
import appConfig from "../app-config-global";

class AuthProviderLocal {

  getToken = async (): Promise<any> => {
    const authorizationToken = await new AuthProvider(Object.assign(appConfig)).getToken()
    return authorizationToken;
  }

  signOut = async (): Promise<any> => {
    console.log('AuthProviderLocal.logout()')
    await new AuthProvider(Object.assign(appConfig)).signOut()
  }

  refreshToken = async (): Promise<void> => {
    localStorage.setItem('RefreshAuthentication', 'true')
    await this.signOut()
  }

  signIn = async (): Promise<any> => {
    console.log("Signing in")
    await new AuthProvider(Object.assign(appConfig)).signIn()
  }
}

// export default AuthProviderLocal

const authProviderLocal = new AuthProviderLocal()
export default authProviderLocal