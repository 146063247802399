import BaseController from "./BaseController";
import {AccountJobModel, AccountModel, AccountsResponse} from "./models/AccountModel";
// import {AccountServiceConversionJobModel} from "./models/AccountServiceConversionJobModel";
import { AccountTypeModel, VoicemailTypeModel } from "./models/AccountTypeModel";
import { CallTreeModel, CallTreeOption } from "./models/CallTreeModel";
import { ChangeLogModel } from "./models/ChangeLogModel";
import { ResponseListModel, ResponseModel } from "./models/ResponseModel";
import { SystemModel } from "./models/SystemModel";
import { store } from "../common/redux/store/store";
import { DeviceModel } from "./models/DeviceModel";
import { NotificationModel } from "../common/redux/reducers/notification";
import { uuidv4 } from "../common/redux/actions/notification";
import { dispatch_addNotification } from "../common/redux/actions/notification";
export const globalStore = store;

class AccountController extends BaseController {
	//Accounts
	getAccountList = async (   searchCriteria?: string,
							   tab?: string,
							   offset?: number,
							   limit?: number
	): Promise<AccountsResponse> => {
		let isAdminView = globalStore.getState().app.adminView;

		let query: any = {
			q: searchCriteria,
		};

		if (!isAdminView) {
			if (tab === "my") {
				query = {
					q: searchCriteria,
					owner: true,
				};
			} else {
				query = {
					q: searchCriteria,
					admin: true,
				};
			}
		}

		// Add offset and limit parameters to the query if provided
		if (offset !== undefined && limit !== undefined) {
			query.result_start = offset;
			query.result_end = offset + limit - 1; // Calculate the end index
		}

		return await this.get("/accounts", query, true).then((response: ResponseListModel) => {
			if (response) {
				return {
					items: response.items,
					total: response.total
				};
			} else {
				return {
					items: [],
					total: 0
				};
			}
		});
	};
	getAccount = async (accountId: string, isAdmin: boolean): Promise<AccountModel> => {
		return await this.get("/accounts/" + accountId+"?"+(isAdmin?"screen=admin":"screen=user")).then((response: ResponseModel) => (response ? response.item : null));
	};

	getAccountLookup = async (id: string): Promise<any> => {
		return await this.get("/account_lookup/" + id, null, true).then((response: ResponseModel) => (response ? response.item : null));
	};

	//3-phase account creation

	// createAccount = async (account: AccountModel): Promise<AccountModel> => {
	// 	return await this.post("/accounts", {
	// 		item: account,
	// 	}).then((response: any) => (response ? response.item : null));
	// };

	createAccount = async (account: AccountModel): Promise<AccountModel> => {
		return await this.post("/accounts", {
			item: account,
			phase: "start",
		}).then((response: any) => (response ? response.item : null));
	};

	completeCreateAccount = async (jobId: string): Promise<AccountModel> => {
		return await this.post("/accounts/jobs/" + jobId, null, true).then((response: any) => (response ? response.item : null));
	};

	monitorAccountJob = async (jobId: string): Promise<AccountJobModel> => {
		return await this.get("/accounts/jobs/" + jobId, null).then((response: any) => (response ? response.item : null));
	};

	startAccountServiceConversion = async (accountUUID: any, kerbid: any):  Promise<any> => {
		return await this.post("/accounts/"+accountUUID+"/convert_start", {
			softclient_username: kerbid,
		}).then((response: any) => (response ? response.item : null));
	};

	completeAccountServiceConversion = async (jobId: string):  Promise<any> => {
		return await this.post("/accounts/convert/jobs/" + jobId, null, true).then((response: any) => (response ? response.item : null));
	};

	monitorAccountServiceConversionJob = async (jobId: string): Promise<any> => {
		return await this.get("/accounts/convert/jobs/" + jobId, null).then((response: any) => (response ? response.item : null));
	};

	getAccountServiceConversionMessage = async (accountId: string): Promise<any> => {
		return await this.get("/accounts/" + accountId+"/convert").then((response: any) => (response ? response.items : null));
	};

	//Update Account 
	updateAccount = async (account: AccountModel): Promise<any> => {
		account.call_control_url=undefined;
		account.legacy_voicemail_control_url=undefined;
		account.voicemail_control_url=undefined;
		account.call_control_banner=undefined;
		account.softphone_banner=undefined;
		
		let jsonString = JSON.stringify(account);
		// console.log("jsonString = ",jsonString);

		const urlRegex = /:\/\//;

		if(urlRegex.test(jsonString)){
			// let match = urlRegex.exec(jsonString);
			// let subtxt="";
			// if(match&&match!==null){
			// 	subtxt=match[0];
			// 	console.log("match = ",match[0]);   
			// }

			const notification: NotificationModel = {
				show: true,
				title: "Error",
				message: 'URL not allowed in fields',
				subtext: 'Error whilst saving account',
				id: uuidv4(),
				type: 1,
			};
			store.dispatch(dispatch_addNotification(notification));
			return null;
		}

		return await this.put("/accounts/" + account.uuid, {
			item: account,
		}).then((response: ResponseModel) => (response ? response.item : null));
	};

	
	//3-phase account creation

	// deleteAccount = async (account: AccountModel): Promise<any> => {
	// 	return await this.delete("/accounts/" + account.uuid).then((response) => (response ? response.item : null));
	// };

	deleteAccount = async (account: AccountModel): Promise<any> => {
		return await this.deleteWithResponse("/accounts/" + account.uuid, { phase: "start" }).then((response) => (response ? response.item : null));
	};

	completeDeleteAccount = async (jobId: string): Promise<AccountModel> => {
		return await this.delete("/accounts/jobs/" + jobId, null, true).then((response: any) => (response ? response.item : null));
	};

	//Remove Device Assignments

	removeDeviceAssignment = async (accountId: string, mac: string): Promise<any> => {
		return await this.delete("/accounts/" + accountId + "/assigned_devices/" + mac);
	};

	updateDeviceAssignmentMWI = async (account: AccountModel, device: DeviceModel, value: boolean): Promise<any> => {
		return await this.put("/accounts/" + account.uuid + "/assigned_devices/" + device.mac + "/mwi", {
			mwi: value,
		});
	};

	//Auxiliary
	getChangeLog = async (accountId: string): Promise<ChangeLogModel[]> => {
		return await this.get("/accounts/" + accountId + "/changelog").then((response: ResponseListModel) => (response ? response.items : null));
	};

	getSystems = async (accountId: string): Promise<SystemModel[]> => {
		return await this.get("/accounts/" + accountId + "/system_lookup").then((response: ResponseListModel) => (response ? response.items : null));
	};

	regenerateSipPassword = async (account: AccountModel): Promise<any> => {
		return await this.post("/accounts/" + account.uuid + "/regenerate_sip_password").then((response) => (response ? response : null));
	};

	setSipPassword = async (account: AccountModel, newPassword: string): Promise<any> => {
		return await this.post("/accounts/" + account.uuid + "/set_sip_password", {
			sip_password: newPassword,
		}).then((response) => (response ? response : null));
	};

	setVoicemailPin = async (account: AccountModel, newPin: string): Promise<any> => {
		return await this.post("/accounts/" + account.uuid + "/set_voicemail_pin", {
			pin: newPin,
		}).then((response) => (response ? response : null));
	};

	changeVoicemailType = async (account: AccountModel, newType: string): Promise<any> => {
		return await this.post("/accounts/" + account.uuid + "/change_voicemail_type", {
			pin: newType,
		}).then((response) => (response ? response.items : null));
	};

	getCallTreeMenu = async (accountId: string): Promise<CallTreeModel> => {
		return this.get("/accounts/" + accountId + "/calltree_menu").then((response) => (response ? response : null));
	};

	uploadCallTreeAnnouncement = async (accountId: string, fileName: string, file: any, fileType:string): Promise<any> => {
		return await this.postFile("/accounts/" + accountId + "/calltree_announcement?file_type="+fileType, fileName, file);
	};

	updateCallTree = async (accountId: string, callTree: CallTreeModel): Promise<CallTreeModel> => {
		return await this.put("/accounts/" + accountId + "/calltree_menu", callTree).then((response) => (response ? response : null));
	};

	//Lookups
	getAccountTypes = async (): Promise<AccountTypeModel[]> => {
		return await this.get("/voip_systems").then((response: ResponseListModel) => (response ? response.items : null));
	};

	getVoicemailTypes = async (): Promise<VoicemailTypeModel[]> => {
		return await this.get("/voicemail_systems").then((response: ResponseListModel) => (response ? response.items : null));
	};

	getCalltreeActions = async (account_type:string): Promise<CallTreeOption[]> => {
		return await this.get("/calltree_options?type="+account_type).then((response: ResponseListModel) => (response ? response.items : null));
	};

	migrateAccount = async (account: AccountModel): Promise<any> => {
		return await this.post("/accounts/" + account.uuid + "/migrate").then((response) => (response ? response : null));
	};

	generateActivationCode = async (account: AccountModel): Promise<any> => {
		return await this.get("/accounts/zoom_rooms/" + account.assigned_room_name + "/regenerate_activation_code").then((response) => (response ? response : null));
	};
}

export default AccountController;
