import * as types from "../constants/ActionTypes";

const initialState = {
	accountTypes: [],
	voicemailTypes: [],
	deviceModels: [],
	firmwareTypes: [],
	switchSites: [],
	framePartitions: [],
	pairStatues: [],
};

export default function app(state = initialState, action: any) {
	switch (action.type) {
		case types.CHANGE_ACCOUNT_TYPES: {
			return { ...state, accountTypes: action.payload };
		}
		case types.CHANGE_VOICEMAIL_TYPES: {
			return { ...state, voicemailTypes: action.payload };
		}
		case types.CHANGE_DEVICE_MODELS: {
			return { ...state, deviceModels: action.payload };
		}
		case types.CHANGE_FIRMWARE_TYPES: {
			return { ...state, firmwareTypes: action.payload };
		}
		case types.CHANGE_SWITCH_SITES: {
			return { ...state, switchSites: action.payload };
		}
		case types.CHANGE_FRAME_PARTITIONS: {
			return { ...state, framePartitions: action.payload };
		}
		case types.CHANGE_PAIR_STATUSES: {
			return { ...state, pairStatues: action.payload };
		}
		default:
			return state;
	}
}
