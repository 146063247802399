import apiConfig from "../api/api-config"
import BaseController from './BaseController';
import { ProfileModel } from './models/ProfileModel';
// import AuthProviderLocal from "../services/AuthProvider";

class ProfileController extends BaseController {
  constructor() {
    super();
  }

  // getProfile = async (): Promise<ProfileModel> => {
  //   return await this.get('/profile').then((response) =>
  //     response ? response : null
  //   );
  // };

  getProfile = async (): Promise<ProfileModel> => {
    return await this.getProfileDetails().then((response) =>
      response ? response : null
    );
  };

  getProfileByKerb = async (kerb: string): Promise<ProfileModel> => {
    return await this.getJsonCheck('/profile/' + kerb).then((response) =>
      response ? response : null
    );
  };

  getPictureByKerb = async (kerb: string): Promise<any> => {
    return await this.getImage('/picture/' + kerb).then((response) =>
      response ? response : null
    );
  };

  getPicture = async (): Promise<any> => {
    return await this.getProfileImage().then((response) =>
      response ? response : null
    );
  };

  // getPicture = async (): Promise<any> => {
  //   return await this.getImage('/picture').then((response) =>
  //     response ? response : null
  //   );
  // };

  // async getProfile (): Promise<Profile> {
  //   const response = await this.useFetch('GET', 'profile', `${this.apiHost}/${this.apiPathDigitalId}/profile`)

  //   if (response.status === 200) {
  //     const data = response.json()
  //     return data
  //   } else {
  //     const text = await response.text()
  //     return await Promise.reject(text)
  //   }
  // }

  // async getProfilePicture (): Promise<Blob> {
  //   const response = await this.useFetch('GET', 'picture', `${this.apiHost}/${this.apiPathDigitalId}/picture`)

  //   if (response.status === 200) {
  //     return response.blob()
  //   } else {
  //     const text = await response.text()
  //     return await Promise.reject(text)
  //   }
  // }

}



export default ProfileController;
