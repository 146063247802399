import * as types from "../constants/ActionTypes";
import { AccountTypeModel, VoicemailTypeModel } from "../../../api/models/AccountTypeModel";
import { DeviceModelModel } from "../../../api/models/DeviceModelModel";

//Actions
export function accountTypesChange(response: any) {
	return {
		type: types.CHANGE_ACCOUNT_TYPES,
		payload: response,
	};
}

export function voicemailTypesChange(response: any) {
	return {
		type: types.CHANGE_VOICEMAIL_TYPES,
		payload: response,
	};
}

export function deviceModelsChange(response: any) {
	return {
		type: types.CHANGE_DEVICE_MODELS,
		payload: response,
	};
}

export function firmwareTypesChange(response: any) {
	return {
		type: types.CHANGE_FIRMWARE_TYPES,
		payload: response,
	};
}

export function switchSitesChange(response: string[]) {
	return {
		type: types.CHANGE_SWITCH_SITES,
		payload: response,
	};
}

export function framePartitionsChange(response: string[]) {
	return {
		type: types.CHANGE_FRAME_PARTITIONS,
		payload: response,
	};
}

export function pairStatusesChange(response: any) {
	return {
		type: types.CHANGE_PAIR_STATUSES,
		payload: response,
	};
}

//Dispatches
export function changeAccountTypes(accountTypes: AccountTypeModel[]) {
	return (dispatch: any) => {
		dispatch(accountTypesChange(accountTypes));
	};
}

export function changeVoicemailTypes(voicemailTypes: VoicemailTypeModel[]) {
	return (dispatch: any) => {
		dispatch(voicemailTypesChange(voicemailTypes));
	};
}

export function changeDeviceModels(deviceModels: DeviceModelModel[]) {
	return (dispatch: any) => {
		dispatch(deviceModelsChange(deviceModels));
	};
}

export function changeFirmwareTypes(firmwareTypes: string[]) {
	return (dispatch: any) => {
		dispatch(firmwareTypesChange(firmwareTypes));
	};
}

export function changeSwitchSites(switchSites: string[]) {
	return (dispatch: any) => {
		dispatch(switchSitesChange(switchSites));
	};
}

export function changeFramePartitions(framePartitions: string[]) {
	return (dispatch: any) => {
		dispatch(framePartitionsChange(framePartitions));
	};
}

export function changePairStatuses(pairStatuses: any) {
	return (dispatch: any) => {
		dispatch(pairStatusesChange(pairStatuses));
	};
}
