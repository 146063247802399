import moment from "moment";

class StringProvider
{
    capitalizeFirstLetter(value: string)
    {
        if (value)
        {
            return value.charAt(0).toUpperCase() + value.toLowerCase().slice(1);
        }

        return "";
    }

    toLongDate(value: string)
    {
        if (value)
        {
            return moment(value, "YYYY-MM-DDTHH:mm:ss").format("MMM DD, YYYY")
        }

        return "";
    }

    toLongDateWithTime(value: string)
    {
        if (value)
        {
            return moment(value, "YYYY-MM-DDTHH:mm:ss").format("MMM DD, YYYY LT")
        }

        return "";
    }

    toTimeFormat(value: string)
    {
        if (value)
        {
            return moment(value, 'HH:mm').format('hh:mm a')
        }

        return "";
    }

    formatPhoneNumber(phoneNumberString: any)
    {
        if (phoneNumberString)
        {
            const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
            const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
            if (match)
            {
                const intlCode = (match[1] ? "+1 " : "");
                return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
            }
        }

        return " - ";
    }

    toUrl(value: string)
    {
        return encodeURIComponent(value)
    }

    fromUrl(value: string)
    {
        if (value)
        {
            return decodeURIComponent(value)
        }

        return "";
    }

    listCapitalizeAll(values: string[])
    {
        if(values){
            return values.map(value => value.toUpperCase())
        }
        return [];
    }

    listCapitalizeFirst(values: string[])
    {
        if(values){
            return values.map(value => this.capitalizeFirstLetter(value))
        }
        return [];
    }

}

export default StringProvider;