import BaseController from "./BaseController";
import { ChangeLogModel } from "./models/ChangeLogModel";
import {DeviceModel, DeviceResponse, PairedDevice, PortSettingsModel} from "./models/DeviceModel";
import { DeviceModelModel } from "./models/DeviceModelModel";
import { AudioCodesPortModel } from "./models/DeviceModel";
import { ResponseListModel, ResponseModel } from "./models/ResponseModel";
import { store } from "../common/redux/store/store";
import { AccountJobModel, AccountModel } from "./models/AccountModel";
export const globalStore = store;

class DeviceController extends BaseController {
	//Devices
	getDeviceList = async (
		searchCriteria?: string,
		offset?: number,
		limit?: number
	): Promise<DeviceResponse> => {
		let isAdminView = globalStore.getState().app.adminView;

		let query: any = {
			q: searchCriteria,
		};

		if (!isAdminView) {
			query.owner = true;
		}

		// Add offset and limit parameters to the query if provided
		if (offset !== undefined && limit !== undefined) {
			query.result_start = offset;
			query.result_end = offset + limit - 1; // Calculate the end index based on the offset and limit
		}

		try {
			const response = await this.get("/devices", query, true);
			// Assuming the backend service returns a 'total' field representing the total count of devices
			return {
				items: response.items || [],
				total: response.total || 0
			};
		} catch (error) {
			console.error("Error fetching device list:", error);
			// Handle error appropriately
			return {
				items: [],
				total: 0
			};
		}
	};

	getUserDevices = async (): Promise<any> => {
		let query: any = {
			owner: true,
		};

		return await this.get("/devices", query, true).then((response: ResponseListModel) => (response ? response : null));
	};

	getAccountDevices = async (accountId: string): Promise<any> => {
		let query: any = {
			account: accountId,
		};

		return await this.get("/devices", query, true).then((response: ResponseListModel) => (response ? response : null));
	};

	getDevice = async (deviceId: string , isAdmin: boolean): Promise<DeviceModel> => {
		return await this.get("/devices/" + deviceId +"?"+(isAdmin?"screen=admin":"screen=user")).then((response: ResponseModel) => (response ? response.item : null));
	};

	getDeviceLookup = async (mac: string): Promise<any> => {
		return await this.get("/device_lookup/" + mac).then((response: ResponseModel) => (response ? response.item : null));
	};

	createDevice = async (device: DeviceModel): Promise<DeviceModel> => {
		return await this.post("/devices", {
			item: device,
		}).then((response: ResponseModel) => (response ? response.item : null));
	};

	updateDevice = async (device: DeviceModel): Promise<DeviceModel> => {
		return await this.put("/devices/" + device.uuid, {
			item: device,
		}).then((response: ResponseModel) => (response ? response.item : null));
	};

	deleteDevice = async (deviceId: string): Promise<any> => {
		return await this.delete("/devices/" + deviceId).then((response) => (response ? response : null));
	};

	//Auxilary
	getChangeLog = async (deviceId: string): Promise<ChangeLogModel[]> => {
		return await this.get("/devices/" + deviceId + "/changelog").then((response: ResponseListModel) => (response ? response.items : null));
	};

	regeneratePasswords = async (deviceId: string): Promise<DeviceModel> => {
		return await this.post("/devices/" + deviceId + "/regenerate_passwords", {});
	};

	clearProvisioningKey = async (deviceId: string): Promise<any> => {
		return await this.post("/devices/" + deviceId + "/clear_provisioningkey");
	};

	regenerateProvisioningKey = async (deviceId: string): Promise<DeviceModel> => {
		return await this.post("/devices/" + deviceId + "/regenerate_provisioningkey");
	};

	rebootDevice = async (deviceId: string): Promise<any> => {
		return await this.post("/devices/" + deviceId + "/reboot_device",
			{phase: "start"});
	};

	initDeviceRebootStatus = async (jobId: string): Promise<any> => {
		return await this.post("/devices/reboot_device/jobs/" + jobId, null, true).then((response: any) => (response ? response.item : null));
	};

	pollDeviceRebootStatus = async (jobId: string): Promise<any> => {
		return await this.get("/devices/reboot_device/jobs/" + jobId).then((response: any) => (response ? response.item : null));
	};

	deviceLookup = async (mac: string): Promise<any> => {
		return await this.get("/device_lookup/" + mac, null, true).then((response: ResponseModel) => (response ? response.item : null));
	};

	assignToAccount = async (deviceId: string, account: any): Promise<any> => {
		return await this.post("/devices/" + deviceId + "/assigned_accounts", account);
	};

	updateDeviceAssignments = async (deviceId: string, assignedAccounts: any): Promise<any> => {
		return await this.put("/devices/" + deviceId + "/assigned_accounts", assignedAccounts);
	};

	removeAccountAssignment = async (deviceId: string, did: string, linePort: string): Promise<any> => {
		return await this.delete("/devices/" + deviceId + "/assigned_accounts/" + did + "/" + linePort);
	};

	updateDeviceAssignmentMWI = async (device: DeviceModel, account: AccountModel, value: boolean): Promise<any> => {
		return await this.put("/devices/" + device.uuid + "/assigned_accounts/" + account.did + "/mwi", {
			mwi: value,
		});
	};

	pairDeviceLookup = async (deviceId: string, mac: string): Promise<PairedDevice> => {
		return await this.post("/devices/" + deviceId + "/hw_pairing", { paired_to: { mac: mac }, validate_only: true }).then((response: ResponseModel) =>
			response
				? response.item
					? response.item
					: {
							description: null,
							mac: mac,
							model: "",
							uuid: "new",
					  }
				: null
		);
	};

	pairDevice = async (deviceId: string, device: PairedDevice): Promise<any> => {
		return await this.post("/devices/" + deviceId + "/hw_pairing", { paired_to: device, validate_only: false });
	};

	removePairedDevice = async (deviceId: string): Promise<any> => {
		return await this.delete("/devices/" + deviceId + "/hw_pairing");
	};

	//Audio Codes

	fetchAudiocodesBoostrapConfig = async (device: DeviceModel): Promise<any> => {
		return await this.getFile("/devices/" + device.uuid + "/analog_gw/bootstrap_config", "text/plain").then((blob) => {
			saveAs(blob, "boostrap_" + device.description + ".ini");
		});
	};

	setMacAddress = async (device: DeviceModel, newMac: string): Promise<any> => {
		return await this.put("/devices/" + device.uuid + "/analog_gw/mac", {
			mac: newMac,
		}).then((response: any) => (response ? response : null));
	};

	//3 Phase port sync

	pushPortAssignments = async (device: DeviceModel): Promise<any> => {
		return await this.post("/devices/" + device.uuid + "/analog_gw/sync_ports", {
			phase: "start",
		}).then((response: ResponseModel) => (response ? response.item : null));
	};

	completePortSync = async (jobId: string): Promise<any> => {
		return await this.post("/devices/analog_gw/sync_ports/jobs/" + jobId, null, true);
	};

	monitorDeviceJob = async (jobId: string): Promise<AccountJobModel> => {
		return await this.get("/devices/analog_gw/sync_ports/jobs/" + jobId, null).then((response: any) => (response ? response.item : null));
	};

	//3 Phase port sync

	getPortAssignment = async (deviceId: string, port: string): Promise<AudioCodesPortModel> => {
		return await this.get("/devices/" + deviceId + "/analog_gw_port/" + port).then((response) => (response ? response.item : null));
	};

	deletePortAssignment = async (deviceId: string, port: string): Promise<any> => {
		return await this.delete("/devices/" + deviceId + "/analog_gw_port/" + port).then((response) => (response ? response : null));
	};

	updatePortAssignment = async (deviceId: string, portId: string, port: PortSettingsModel): Promise<any> => {
		return await this.put("/devices/" + deviceId + "/analog_gw_port/" + portId, {
			item: port,
		}).then((response) => (response ? response : null));
	};

	getDeviceDid = async (hostname: string, portNumber: number): Promise<string> => {
		return await this.get("/devices/analog_gw_port/dids", {
			hostname: hostname,
			port_number: portNumber.toString(),
		}).then((response) => (response ? response.item : null));
	};

	getSipAuth = async (url: string): Promise<string> => {
		if(url.startsWith("/voip")){
			url = url.substring(5);
		}
		return await this.get(url).then((response) => (response ? response.item : null));
	};

	/**
	 * Only used for the repair.  Suppress the error message.
	 * @param url 
	 * @returns 
	 */
	repairSip = async (url: string): Promise<string> => {
		if(url.startsWith("/voip")){
			url = url.substring(5);
		}
		return await this.get(url, null, false, true).then((response) => (response ? response.item : null));
	};

	//Lookups
	getDeviceModels = async (): Promise<DeviceModelModel[]> => {
		return await this.get("/device_models").then((response: ResponseListModel) => (response ? response.items : null));
	};

	getFirmwareTypes = async (): Promise<string[]> => {
		return await this.get("/firmware_types").then((response: ResponseListModel) => (response ? response.items : null));
	};
}

export default DeviceController;
