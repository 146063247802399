import BaseController from "./BaseController";
import { ChangeLogModel } from "./models/ChangeLogModel";
import { PairModel } from "./models/PairModel";
import { PairRangeModel } from "./models/PairRangeModel";
import { ResponseListModel, ResponseModel } from "./models/ResponseModel";

class PairController extends BaseController {
	getSwitchSites = async (): Promise<string[]> => {
		let response: ResponseListModel = await this.get("/pairtracking/switch_sites");
		return response ? response.items : null;
	};

	getFramePartitions = async (): Promise<string[]> => {
		let response: ResponseListModel = await this.get("/pairtracking/frame_partitions");
		return response ? response.items : null;
	};

	getAudioCodesDevices = async (): Promise<string[]> => {
		let response: ResponseListModel = await this.get("/devices/analog_gws/hostnames");
		return response ? response.items : null;
	};

	getPairStatuses = async (): Promise<string[]> => {
		let response: ResponseListModel = await this.get("/pairtracking/cable_statuses");
		return response ? response.items : null;
	};

	//Pairs
	getPairList = async (searchCriteria?: any): Promise<PairModel[]> => {
		let response: ResponseListModel = await this.get("/pairtracking/pairs", searchCriteria);
		return response ? response.items : null;
	};

	getPair = async (pairId: string): Promise<PairModel> => {
		let response: ResponseModel = await this.get("/pairtracking/pairs/" + pairId);
		return response ? response.item : null;
	};

	findPair = async (switchSite: string, framePartition: string, pairNumber: string): Promise<PairModel[]> => {
		let response: ResponseListModel = await this.get("/pairtracking/pairs/", {
			switch_site: switchSite,
			frame_partition: framePartition,
			pair_number: pairNumber,
		});
		return response ? response.items : null;
	};

	getPairChangeLog = async (pairId: string): Promise<ChangeLogModel[]> => {
		let response: ResponseListModel = await this.get("/pairtracking/pairs/" + pairId + "/changelog");
		return response ? response.items : null;
	};

	updatePair = async (pair: PairModel): Promise<PairModel> => {
		let response: ResponseModel = await this.put("/pairtracking/pairs/" + pair.uuid, pair);
		return response ? response.item : null;
	};

	//Pair Ranges

	getPairRangeList = async (searchCriteria?: string): Promise<PairRangeModel[]> => {
		let pairRangeList = await this.get("/pairtracking/ranges", {
			q: searchCriteria,
		});
		return pairRangeList ? pairRangeList.items : null;
	};

	getPairRange = async (pairRangeId: string): Promise<PairRangeModel> => {
		let response = await this.get("/pairtracking/ranges/" + pairRangeId);
		return response ? response.item : null;
	};

	getPairRangeChangeLog = async (pairRangeId: string): Promise<ChangeLogModel[]> => {
		let response = await this.get("/pairtracking/ranges/" + pairRangeId + "/changelog");
		return response ? response.items : null;
	};

	createPairRange = async (pairRange: PairRangeModel): Promise<PairRangeModel> => {
		let response = await this.post("/pairtracking/ranges", pairRange);
		return response ? response.item : null;
	};

	updatePairRange = async (pairRange: PairRangeModel): Promise<PairRangeModel> => {
		let response = await this.put("/pairtracking/ranges/" + pairRange.uuid, pairRange);
		return response ? response.item : null;
	};

	deletePairRange = async (pairRange: PairRangeModel): Promise<any> => {
		let response = await this.delete("/pairtracking/ranges/" + pairRange.uuid);
		return response ? response : null;
	};
}

export default PairController;
