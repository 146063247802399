import BaseController from "./BaseController";
import { UserModel } from "./models/AccountModel";
import { BannerMessageModel } from "./models/BannerMessageModel";
import { ResponseListModel } from "./models/ResponseModel";
import { StatusModel } from "./models/StatusModel";

class AppController extends BaseController {
	getHeartBeat = async (): Promise<any> => {
		return await this.get("/heartbeat/ping").then((response: ResponseListModel) => (response ? response.items : null));
	};

	getStatus = async (): Promise<StatusModel[]> => {
		return await this.get("/heartbeat/status").then((response: ResponseListModel) => (response ? response.items : null));
	};

	getUserRoles = async (): Promise<any> => {
		return await this.get("/acl/user").then((response) => (response ? response.functions : null));
	};

	getAdminRoles = async (): Promise<any> => {
		return await this.get("/acl/admin").then((response) => (response ? response.functions : null));
	};

	getBannerMessages = async (): Promise<BannerMessageModel[]> => {
		return await this.get("/banner_messages").then((response: ResponseListModel) => (response ? response.items : null));
	};

	deleteBannerMessage = async (messageId: string): Promise<BannerMessageModel[]> => {
		return await this.delete("/banner_messages/" + messageId).then((response: any) => (response ? response : null));
	};

	searchUsers = async (search: string): Promise<UserModel> => {
		return await this.get(
			"/trustees",
			{
				q: search,
			},
			false,
			false
		).then((response: ResponseListModel) => {
			return (response ? response.items : null)
		});
	};

	getZoomRooms = async () => {
		return await this.get("/accounts/zoom_rooms").then((response) => (response ? response : null));
	}
	
}

export default AppController;
